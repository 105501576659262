(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/griditem-twister/views/item.js') >= 0) return;  svs.modules.push('/components/banner_layouts/griditem-twister/views/item.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.griditem_twister=_svs.banner_layouts.griditem_twister||{};
_svs.banner_layouts.griditem_twister.templates=_svs.banner_layouts.griditem_twister.templates||{};
svs.banner_layouts.griditem_twister.templates.item = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div\n    class=\"banner-griditem-twister banner-common-griditem banner-griditem-branding-poker f-content\"\n    data-impression=\"true\"\n    data-impression-tracking=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"trackingString") || (depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trackingString","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":48}}}) : helper)))
    + "\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "  >\n    <div class=\"banner-background-clip\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"landscapeImage") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":16,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"portraitImage") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":21,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"banner-action-container\">\n      <div class=\"banner-action-text-container\">\n        <div class=\"f-section-title\">Spela om upp till <span>"
    + alias4((lookupProperty(helpers,"formatGameType")||(depth0 && lookupProperty(depth0,"formatGameType"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"gameType") : depth0),"getMaxValueSEK",{"name":"formatGameType","hash":{},"data":data,"loc":{"start":{"line":25,"column":61},"end":{"line":25,"column":105}}}))
    + " kr</span></div>\n      </div>\n      <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"exchangeRate") || (depth0 != null ? lookupProperty(depth0,"exchangeRate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"exchangeRate","hash":{},"data":data,"loc":{"start":{"line":27,"column":11},"end":{"line":27,"column":27}}}) : helper)))
    + "</div>\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"block":true,"class":"twister-btn"},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":6},"end":{"line":33,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"gradientOpacity") : depth0),"!==","0",{"name":"compare","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":4},"end":{"line":37,"column":16}}})) != null ? stack1 : "")
    + "    <div class=\"banner-main-content\">\n      <div class=\"banner-main-text\">\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":40,"column":77}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"caption") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":41,"column":84}}})) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n    <div class=\"banner-header\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-product_header-product_header"),depth0,{"name":"components-lb_ui-product_header-product_header","hash":{"class":"banner-header-tablet","heightMode":"tablet","branding":"poker","emblem":"true"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-product_header-product_header"),depth0,{"name":"components-lb_ui-product_header-product_header","hash":{"class":"banner-header-desktop","heightMode":"desktop","branding":"poker","emblem":"true"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    data-cmsid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"bannerId") || (depth0 != null ? lookupProperty(depth0,"bannerId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"bannerId","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":28}}}) : helper)))
    + "\"\n    data-cmstype=\"banner\"\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"banner-background-landscape\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"landscapeImage") : depth0),{"name":"components-content-image_v2-image","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"banner-background-portrait\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"portraitImage") : depth0),{"name":"components-content-image_v2-image","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        ca <span>"
    + container.escapeExpression((lookupProperty(helpers,"formatGameType")||(depth0 && lookupProperty(depth0,"formatGameType"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"gameType") : depth0),"getValueSEK",{"name":"formatGameType","hash":{},"data":data,"loc":{"start":{"line":32,"column":17},"end":{"line":32,"column":58}}}))
    + " kr</span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"banner-griditem-gradient banner-griditem-gradient-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"gradientOpacity") || (depth0 != null ? lookupProperty(depth0,"gradientOpacity") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gradientOpacity","hash":{},"data":data,"loc":{"start":{"line":36,"column":66},"end":{"line":36,"column":85}}}) : helper)))
    + "\"></div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"banner-twister-title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":40,"column":55},"end":{"line":40,"column":64}}}) : helper)))
    + "</div>";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"banner-twister-subtitle\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"caption") || (depth0 != null ? lookupProperty(depth0,"caption") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"caption","hash":{},"data":data,"loc":{"start":{"line":41,"column":60},"end":{"line":41,"column":71}}}) : helper)))
    + "</div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-banner_actions-twister_button-twister_button"),depth0,{"name":"components-banner_actions-twister_button-twister_button","hash":{"gameType":(depth0 != null ? lookupProperty(depth0,"buyIn") : depth0),"crm":(depth0 != null ? lookupProperty(depth0,"crm") : depth0),"position":(depth0 != null ? lookupProperty(depth0,"position") : depth0),"clickTrackingString":(depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-banner_layouts-griditem_twister-item'] = svs.banner_layouts.griditem_twister.templates.item;
})(svs, Handlebars);


 })(window);